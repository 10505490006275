import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
    return (
        <>
            <div className="logo-bar">
                <div className="logo">
                    <img src="runsitelogo.jpg" alt="Logo" />
                </div>
            </div>
            <header className="header">
                <nav className="navbar">
                    <Link to="/">ABOUT</Link>
                    <Link to="/testimonials">TESTIMONIALS</Link>
                    <Link to="/contact">CONTACT</Link>
                </nav>
            </header>
        </>
    );
}

export default Header;
