import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import MainSection from './MainSection'; // Your 'Home' component
import ProgramSection from './ProgramSection'; // Your 'Services' component
import ContactSection from './ContactSection'; // Your 'Contact' component
import TestimonialSection from './TestimonialSection';

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <Routes>
                    <Route path="/" element={<MainSection />} />
                    <Route path="/services" element={<ProgramSection />} />
                    <Route path="/contact" element={<ContactSection />} />
                    <Route path="/testimonials" element={<TestimonialSection />} />
                    {/* ... other routes if necessary */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
