import React from 'react';

function ProgramSection() {
    return (
        <div id="program" className="program-section">
            <h2>My Programs</h2>
            {/* List programs here */}
        </div>
    );
}

export default ProgramSection;
