import React, { useState } from 'react';
import './MainSection.css';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import instagramLogo from './instagramLogo.png';
import discordLogo from './discordLogo.jpg';
import linkedinLogo from './linkedinlogo.jpg';

function MainSection() {
    const [step, setStep] = useState(1);
    const [userResponses, setUserResponses] = useState({
        ageRange: '',
        heightFeet: '5',
        heightInches: '0',
        service: '',
        gender: '',
        goals: '',
        fullName: '', // Add fullName field
        email: '', // Add email field
        instagramHandle: '', // Add instagramHandle field
    });

    // const handleOptionClick = (option) => {
    //     setUserResponses({ ...userResponses, service: option });
    // };

    const handleNextClick = () => {
        setStep(step + 1);
    };

    const handleBackClick = () => {
        setStep(step - 1);
    };

    const handleInputChange = (event) => {
        setUserResponses({ ...userResponses, [event.target.name]: event.target.value });
    };

    const handleSubmit = (e) => {
        // Send email using Email.js
        const EMAILJS_SERVICE_ID = 'service_q1ev1is';
        const EMAILJS_TEMPLATE_ID = 'template_j2djpue';
        const EMAILJS_USER_ID = 'O_d8SA7US9aQHE0xq';

        // Prepare the email parameters including user responses
        const templateParams = {
            to_email: 'recipient@example.com', // Replace with the recipient's email address
            from_name: 'Your Name', // Replace with your name
            ageRange: userResponses.ageRange,
            heightFeet: userResponses.heightFeet,
            heightInches: userResponses.heightInches,
            service: userResponses.service,
            gender: userResponses.gender,
            goals: userResponses.goals,
            fullName: userResponses.fullName, // Include fullName in the email
            email: userResponses.email, // Include email in the email
            instagramHandle: userResponses.instagramHandle, // Include instagramHandle in the email
        };

        emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams, EMAILJS_USER_ID)
            .then((response) => {
                console.log('Email sent successfully:', response);
                Swal.fire('Good job!', 'Your form has been submitted successfully!', 'success');
                // Handle success (e.g., show a success message)
            })
            .catch((error) => {
                console.error('Email sending failed:', error);
                // Handle error (e.g., show an error message)
            });

        e.preventDefault();

    };

    const isSelected = (option) => {
        return userResponses.service === option || userResponses.gender === option || userResponses.ageRange === option;
    };

    return (
        <div className="main-section">
            <div className="upper-section">
                <header>
                    <h1>
                        <img src="totalstrengthsiteimage.jpg" alt="FrontImage" style={{ maxWidth: '500px', height: 'auto' }} />
                    </h1>
                </header>

                <div className="content-wrapper">
                    <div className="content-section">
                        <p>Hi! This is Terry White Jr! As a NSCA certified personal trainer, I bring a wealth of knowledge and expertise in strength training and running coaching.  I've worked with individuals from various backgrounds and fitness levels, empowering them to achieve their goals and excel in their fitness journeys. In addition, I have pursued an associate's degree in exercise science in which I utilize to focus my methodologies around strength training, running techniques, and endurance building. I thrive on helping clients unlock their potential, whether it's hitting new personal bests or conquering challenging races. 
                        </p>
                    </div>
                </div>
            </div>
            <div className="form-wrapper">
                <div className="form-section">
                    {step === 1 && (
                        <>
                            <div className="question">
                                <p>What are your goals?</p>
                            </div>
                            <div className="options">
                                {['Fat Loss', 'Muscle Gain', 'Event Training', 'Other'].map((goal) => (
                                    <button
                                        key={goal}
                                        onClick={() => setUserResponses({ ...userResponses, goals: goal })}
                                        className={userResponses.goals === goal ? 'option-button selected' : 'option-button'}
                                    >
                                        {goal === 'Event Training' ? 'Training for a Special Event (e.g., race or fitness contest)' : goal}
                                    </button>
                                ))}
                            </div>
                            <button className="next-button" onClick={handleNextClick}>Next →</button>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <div className="question">
                                <p>What is your age range?</p>
                            </div>
                            <div className="options">
                                {['18-25', '26-35', '36-44', '45+'].map((option) => (
                                    <button
                                        key={option}
                                        onClick={() => setUserResponses({ ...userResponses, ageRange: option })}
                                        className={isSelected(option) ? 'option-button selected' : 'option-button'}
                                    >
                                        {option}
                                    </button>
                                ))}
                            </div>
                            <button className="back-button" onClick={handleBackClick}>Back</button>
                            <button className="next-button" onClick={handleNextClick}>Next →</button>
                        </>
                    )}
                    {step === 3 && (
                        <>
                            <div className="question">
                                <p>What is your height?</p>
                            </div>
                            <div className="options">
                                <select
                                    name="heightFeet"
                                    value={userResponses.heightFeet}
                                    onChange={handleInputChange}
                                >
                                    {Array.from({ length: 6 }, (_, i) => (
                                        <option key={i} value={i + 2}>{i + 2} feet</option>
                                    ))}
                                </select>
                                <select
                                    name="heightInches"
                                    value={userResponses.heightInches}
                                    onChange={handleInputChange}
                                >
                                    {Array.from({ length: 12 }, (_, i) => (
                                        <option key={i} value={i}>{i} inches</option>
                                    ))}
                                </select>
                            </div>
                            <button className="back-button" onClick={handleBackClick}>Back</button>
                            <button className="next-button" onClick={handleNextClick}>Next →</button>
                        </>
                    )}
                    {step === 4 && (
                        <>
                            <div className="question">
                                <p>How do you identify?</p>
                            </div>
                            <div className="options">
                                {['Male', 'Female', 'Non-Binary', 'Prefer Not to Say'].map((gender) => (
                                    <button
                                        key={gender}
                                        onClick={() => setUserResponses({ ...userResponses, gender })}
                                        className={isSelected(gender) ? 'option-button selected' : 'option-button'}
                                    >
                                        {gender}
                                    </button>
                                ))}
                            </div>
                            <button className="back-button" onClick={handleBackClick}>Back</button>
                            <button className="next-button" onClick={handleNextClick}>Next →</button>
                        </>
                    )}
                    {step === 5 && (
                        <>
                            <div className="question">
                                <p>Contact Information</p>
                            </div>
                            <div className="options" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    name="fullName"
                                    value={userResponses.fullName}
                                    onChange={handleInputChange}
                                    placeholder="What is your full name?"
                                    style={{ width: '90%', maxWidth: '500px', padding: '10px', marginBottom: '10px' }}
                                />
                                <input
                                    type="text"
                                    name="email"
                                    value={userResponses.email}
                                    onChange={handleInputChange}
                                    placeholder="What is your email?"
                                    style={{ width: '90%', maxWidth: '500px', padding: '10px', marginBottom: '10px' }}
                                />
                                <input
                                    type="text"
                                    name="instagramHandle"
                                    value={userResponses.instagramHandle}
                                    onChange={handleInputChange}
                                    placeholder="What is your Instagram handle?"
                                    style={{ width: '90%', maxWidth: '500px', padding: '10px', marginBottom: '10px' }}
                                />
                            </div>
                            <button className="back-button" onClick={handleBackClick}>Back</button>
                            <button className="next-button" onClick={handleSubmit}>Submit</button>
                        </>
                    )}


                </div>
            </div>
            <p style={{ fontWeight: '100', fontSize: '48px', margin: '0 0 10px 0', marginBottom: '50px', marginTop: '100px' }}>FOLLOW ME</p>
            <div className="social-links" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {/* Instagram link */}
                <a href="https://www.instagram.com/terrywhitejr" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
                    <img src={instagramLogo} alt="Instagram" style={{ width: '100px', height: '100px' }} />
                </a>
                {/* Discord link */}
                <a href="https://discord.gg/yourinvitecode" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
                    <img src={discordLogo} alt="Discord" style={{ width: '100px', height: '100px' }} />
                </a>
                <a href="https://www.linkedin.com/in/terry-white-jr-741a8416/" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
                    <img src={linkedinLogo} alt="LinkedIn" style={{ width: '100px', height: '100px' }} />
                </a>
            </div>
        </div>
    );
}

export default MainSection;













